import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Company from "../components/seo-companys"
import rightArrow from "../assets/img/right-arrow.png"

import social from "../assets/img/social-media-management.png"
import lion from "../assets/img/lion.png"
import sokrates from "../assets/img/sokrates.png"
import able from "../assets/img/able.png"
import eye from "../assets/img/eye.png"
import balance from "../assets/img/balance-time-money.png"
import achievement from "../assets/img/achievement-female.png"
import videoSearch from "../assets/img/video-search.png"
import cv from "../assets/img/cv-curriculum.png"
import maleMan from "../assets/img/male-man.png"
import business from "../assets/img/business-idea.png"
import work from "../assets/img/work-preformance.png"
import Teams from "../components/teams/social-team"
import SSS from "../components/sss/social-sss"

import tipsContent1 from "../assets/img/4-tips-conten1.png"
import tipsContent2 from "../assets/img/4-tips-conten2.png"
import tipsContent3 from "../assets/img/4-tips-conten3.png"
import tipsContent4 from "../assets/img/4-tips-conten4.png"
import circle from "../assets/img/circle.png"
import checkModal from "../assets/img/check-modal.png"

class SocialMedia extends Component {
  constructor(props) {
    super(props)

    this.state = {
      display: false,
      value: "",
    }
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(e) {
    e.preventDefault()

    this.setState({ value: e.target.value })
  }
  showModal() {
    this.setState({ display: !this.state.display })
    document.querySelector(".check-modal").classList.add("active-modal")
  }
  closeModal() {
    this.setState({ display: !this.state.display })
    document.querySelector(".check-modal").classList.remove("active-modal")
  }
  handleSubmit(e) {
    e.preventDefault()
    const title = document.title.split("|", 1).toString()
    const response = fetch(
      "https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7",
      {
        method: "POST",
        body: JSON.stringify({
          text_327649: title,
          text_204202: document.querySelector("#site").value,
          text_775908: document.querySelector("#name").value,
          text_532192: document.querySelector("#email").value,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
    response.then(res => {
      if (res.status === 200) {
        document.querySelector(".status").innerHTML =
          "Mesajınız başarıyla gönderilmiştir."
      } else {
        document.querySelector(".status").innerHTML = "Bir hata oluştu."
      }
    })
  }
  render() {
    return (
      <Layout>
        <SEO
          title="Social Media"
          description=" We follow the changing social media trends every day and design unique content… Content that makes a difference, content that will affect the masses."
        />
        <div className="seo-service container">
          <div className="seo-service__head">
            <div className="col-md-6 noPadding">
              <h1>Social Media</h1>
              <span className="red-head">SERVICES</span>
              <p>
                We follow the changing social media trends every day and design
                unique content… Content that makes a difference, content that
                will affect the masses.
              </p>
              <input
                type="text"
                className="pink-input"
                placeholder="Enter e-mail address."
                value={this.state.value}
                onChange={this.handleChange}
              />
              <button
                className="red-button"
                id="social-button"
                onClick={e => this.showModal()}
              >
                Get your special offer{" "}
                <img alt="alt text" src={rightArrow}></img>
              </button>
            </div>
            <div
              className="col-md-6 newser"
              
            >
              <img className="newser" alt="alt text" src={social} />{" "}
            </div>
            <div className="check-modal">
              <span onClick={e => this.closeModal()}>X</span>
              <h2 className="form-head">Fill the form</h2>{" "}
              <p className="form-desc">
              Enter your information in the fields to get information, and we will get back to you as soon as possible.
              </p>
              <div className="check-modal_container">
                <form onSubmit={this.handleSubmit}>
                  <input
                    type="text"
                    name="text_204202"
                    id="site"
                    required
                    placeholder="Enter your website."
                  />
                  <input
                    type="text"
                    id="name"
                    required
                    name="text_775908"
                    placeholder="Enter your name"
                  />
                  <input
                    type="email"
                    id="email"
                    required
                    name="text_532192"
                    value={this.state.value}
                    onChange={this.handleChange}
                    autoFocus={true}
                    placeholder="Enter yor email"
                  />
                  <button>SEND</button>
                  <div className="status"></div>
                </form>
                <img alt="alt text" src={checkModal} />
              </div>
            </div>
          </div>
        </div>
        <div className="container brands_content py60">
          <h2>
          Some of our prominent brands.
            <svg
              className="comment-icon"
              id="Group_817"
              data-name="Group 817"
              xmlns="http://www.w3.org/2000/svg"
              width="50.007"
              height="55.09"
              viewBox="0 0 87.007 85.09"
            >
              <g
                id="Group_813"
                data-name="Group 813"
                transform="translate(0 0)"
              >
                <path
                  id="Path_23741"
                  data-name="Path 23741"
                  d="M70.6,0H16.409A16.409,16.409,0,0,0,0,16.409V46.938A16.409,16.409,0,0,0,16.409,63.347h5.263a4.458,4.458,0,0,1,3.316,1.478l17.223,19.16a3.336,3.336,0,0,0,5.034-.083L63.2,64.935a4.459,4.459,0,0,1,3.412-1.588H70.6A16.409,16.409,0,0,0,87.007,46.938V16.409A16.41,16.41,0,0,0,70.6,0Z"
                  transform="translate(0 0)"
                  fill="#ed4a57"
                />
              </g>
              <g
                id="Group_816"
                data-name="Group 816"
                transform="translate(17.681 19.866)"
              >
                <g id="Group_814" data-name="Group 814">
                  <path
                    id="Path_23742"
                    data-name="Path 23742"
                    d="M311.748,326.479a7.388,7.388,0,0,0-13.507-3.646.183.183,0,0,1-.306,0A7.387,7.387,0,1,0,287.4,332.884l9.87,9.095a.991.991,0,0,0,1.333.009l10-8.984A7.379,7.379,0,0,0,311.748,326.479Z"
                    transform="translate(-284.414 -319.562)"
                    fill="#fff"
                  />
                </g>
                <g
                  id="Group_815"
                  data-name="Group 815"
                  transform="translate(39.121 1.067)"
                >
                  <path
                    id="Path_23743"
                    data-name="Path 23743"
                    d="M918.009,339.391h-.065l-3.68,1.987-.553-2.182,4.625-2.475h2.443v21.17h-2.768Z"
                    transform="translate(-913.71 -336.72)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </h2>
          <div className="companys__social">
            <Company />
          </div>
        </div>

        <div className="acquire-bottom social-media-contents">
          <div className="acquire-bottom__right">
            <div className="acquire-bottom__left-content">
              <h2>
              Fun, creative
                <br />
                and distinctive <br />
                content production.
              </h2>
              <p>We design content suitable for social media dynamics.</p>
            </div>
            <div className="acquire-bottom__right-img">
              <img alt="alt text" src={lion} />
            </div>
          </div>

          <div className="tips-content container">
            <p className="center-img">CONTENT</p>
            <img alt="alt text" className="circ" src={circle} />
            <div className="row">
              <div className="col-md-6 tcont">
                <img alt="alt text" src={tipsContent1} />
                <p className="firstImgP">
                First, we create  <br/> a concept.
                </p>
              </div>
              <div className="col-md-6 tcont">
                <img alt="alt text" src={tipsContent2} />
                <p className="secImgP">
                Then we <br/> manage the content.
                </p>
              </div>
              <div className="col-md-6 tcont">
                <img alt="alt text" src={tipsContent3} />
              </div>
              <div className="col-md-6 tcont">
                <img alt="alt text" src={tipsContent4} />
              </div>
            </div>
          </div>

          <div className="acquire-bottom__left">
            <div className="acquire-bottom__left-img">
              <img alt="alt text" src={sokrates} />
            </div>
            <div className="acquire-bottom__left-content">
              <h2>
              Community <br />
              management.
              </h2>
              <p>
              We are familiar with your target audience. Therefore we communicate with them through the most appropriate channels. In the event of a crisis, we create loyalty by taking the right actions.

</p>
            </div>
          </div>

          <div className="acquire-bottom__right">
            <div className="acquire-bottom__left-content" id="icerik-pazarlama">
              <h2>
              Reputation <br />
              management.
              </h2>
              <p>
              We create social media profiles that fit the line and image of your brand. We position your brand in the most profitable and preferable way in digital channels.
              </p>
            </div>
            <div className="acquire-bottom__right-img">
              <img alt="alt text" src={able} />
            </div>
          </div>

          <div className="acquire-bottom__left">
            <div className="acquire-bottom__left-img">
              <img alt="alt text" src={eye} />
            </div>
            <div className="acquire-bottom__left-content">
              <h2>
              Social media
                <br />
                campaigns.
              </h2>
              <p>
              We build social media campaigns that will influence your target audience and help you go viral in the digital world, taking you one step further.
              </p>
            </div>
          </div>
        </div>

        <div
          className="container manage-google"
          style={{ padding: "40px 60px" }}
        >
          <h2>
          Why you should choose us <br />
          for social media management?
          </h2>
          <p className="manage-google_p">
          We offer data-driven marketing strategies, creative campaigns that attract <br/> significant attention,
           instant crisis management, and productive results analysis.
          </p>
          <div>
            <div className="col-md-6 manage-google_item">
              <img alt="alt text" src={balance} />
              <div>
                <h3>Working with Professional Experts</h3>
                <p>
                Our team of experts manages your social media account, not trainees or less experienced people.
                </p>
              </div>
            </div>
            <div className="col-md-6 manage-google_item">
              <img alt="alt text" src={achievement} />
              <div>
                <h3>Creating Value</h3>
                <p>
                We focus on high-impact work to increase your return on investment, and we constantly measure the KPIs we set for our goals related to your brand.
                </p>
              </div>
            </div>
            <div className="col-md-6 manage-google_item">
              <img alt="alt text" src={videoSearch} />
              <div>
                <h3>Effective Communication Methods </h3>
                <p>
                We arrange regular meetings with you to review the performance of social media campaigns and have discussions for new ideas.
                </p>
              </div>
            </div>
            <div className="col-md-6 manage-google_item">
              <img alt="alt text" src={cv} />
              <div>
                <h3>A Very Special Report For You</h3>
                <p>
                We regularly report the results of all performance indicators and the most important statistics for your brand.
                </p>
              </div>
            </div>
            <div className="col-md-6 manage-google_item">
              <img alt="alt text" src={maleMan} />
              <div>
                <h3>Accurate Brand Positioning</h3>
                <p>
                By interacting with the right audience through the right channels, we position your brand in accordance with your image and undertake works with viral power to create a positive perception.
                </p>
              </div>
            </div>
            <div className="col-md-6 manage-google_item">
              <img alt="alt text" src={business} />
              <div>
                <h3>Affordable Pricing</h3>
                <p>
                The fee for our social media services is determined by your brand goals and the scope of work according to your expectations. Accordingly, a monthly prepayment is requested. Apart from this, no exceptional charges are requested.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="onboarding container py60">
          <div className="col-md-4">
            <img alt="alt text" src={work} />
            <h2>
            Our operation<br/> process
            </h2>
            <p>
            Check out how we maintain our social media management process for our brands.
            </p>
          </div>
          <div className="col-md-8">
            <div className="hiring-tabs">
              <input type="checkbox" name="onboarding" id="tab6" />
              <input type="checkbox" name="onboarding" id="tab7" />
              <input type="checkbox" name="onboarding" id="tab8" />
              <input type="checkbox" name="onboarding" id="tab9" />
              <input type="checkbox" name="onboarding" id="tab10" />
              <input type="checkbox" name="onboarding" id="tab11" />

              <div className="tab" id="tab6sec">
                <label htmlFor="tab6"> </label>
                <h3>
                  {" "}
                  <span>1</span> Discovery interview.
                </h3>
                <p>
                  - In order to start the procedure, we conduct a meeting to get to know you and your brand. In this meeting, we focus on the sector that you are working in, as well as your goals, your competitive advantages, your target audience, and other factors affecting your performance.{" "}
                </p>
                <div className="plus"></div>
              </div>
              <div className="tab" id="tab7sec">
                <label htmlFor="tab7"> </label>
                <h3>
                  {" "}
                  <span>2</span> Accounts are audited and opportunities are revealed.
                </h3>
                <p>
                  - We analyze the current performance in your social media channels line with the data that we will use while creating our future strategies.
                </p>
                <div className="plus"></div>
              </div>
              <div className="tab" id="tab8sec">
                <label htmlFor="tab8"> </label>
                <h3>
                  {" "}
                  <span>3</span> Findings are discussed to move <br/>on to the next steps.
                </h3>
                <p>
                  - We deliver a face-to-face or video talk to offer you growth opportunities, alongside with a detailed business plan and ROI calculations.
                </p>
                <div className="plus"></div>
              </div>
              <div className="tab" id="tab9sec">
                <label htmlFor="tab9"> </label>
                <h3>
                  {" "}
                  <span>4</span> An initial strategy is created <br/> and action is taken.
                </h3>
                <p>
                  - We focus on actions that will have a positive impact in the digital world, in line with the first 3-month priority plan based on the findings from our audit.
                </p>
                <div className="plus"></div>
              </div>
              <div className="tab" id="tab10sec">
                <label htmlFor="tab10"> </label>
                <h3>
                  {" "}
                  <span>5</span> Optimizations and detailed monthly <br/> reports are maintained.
                </h3>
                <p>
                  - We meet with you weekly to review performance, follow the agenda and generate new ideas.{" "}
                </p>
                <div className="plus"></div>
              </div>
              <div className="tab" id="tab11sec">
                <label htmlFor="tab11"> </label>
                <h3>
                  {" "}
                  <span>6</span> Strategic planning sessions for the next <br/> quarter are held every three months.
                </h3>
                <p>
                  - Ongoing optimizations and strategic plans are discussed, a detailed analysis evaluation is made for the next period, and your budget is managed to offer you the highest value.
                </p>
                <div className="plus"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="team">
          <h2 style={{ textAlign: "center", marginBottom: 120 }}>
            {" "}
            Our social media experts <br/> who can help you.
          </h2>
          <Teams />
        </div>
        <div className="hiring" style={{ marginTop: -80 }}>
          <h2>Frequently Asked Questions</h2>
          <SSS />
        </div>
      </Layout>
    )
  }
}
export default SocialMedia
